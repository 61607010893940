export default {
  defaultAddress: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
  contractName: 'SignMessageLib',
  version: '1.3.0',
  released: true,
  networkAddresses: {
    647: '0xD03427AA22B1E1f2915e1EB93a791E6b7253eE2C',
    416: '0x5eB8bbecc9A3FE0f7e945DD1184a6D5Ce97083f9',
    80001: '0xA65387F16B013cf2Af4605Ad8aA5ec25a2cbA3a2',
  },
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'bytes32',
          name: 'msgHash',
          type: 'bytes32',
        },
      ],
      name: 'SignMsg',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'bytes',
          name: 'message',
          type: 'bytes',
        },
      ],
      name: 'getMessageHash',
      outputs: [
        {
          internalType: 'bytes32',
          name: '',
          type: 'bytes32',
        },
      ],
      stateMutability: 'view',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'bytes',
          name: '_data',
          type: 'bytes',
        },
      ],
      name: 'signMessage',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function',
    },
  ],
}
