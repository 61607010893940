export default {
  defaultAddress: '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
  released: true,
  contractName: 'GnosisSafeProxyFactory',
  version: '1.3.0',
  networkAddresses: {
    647: '0xB7B9FEfBBe636C93E65FAe499169A7CD7a4338e2',
    416: '0x602dD5309e395F59E18B0D65869b04939BbAA475',
    80001: '0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2',
  },
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'contract GnosisSafeProxy',
          name: 'proxy',
          type: 'address',
        },
        {
          indexed: false,
          internalType: 'address',
          name: 'singleton',
          type: 'address',
        },
      ],
      name: 'ProxyCreation',
      type: 'event',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_singleton',
          type: 'address',
        },
        {
          internalType: 'bytes',
          name: 'initializer',
          type: 'bytes',
        },
        {
          internalType: 'uint256',
          name: 'saltNonce',
          type: 'uint256',
        },
      ],
      name: 'calculateCreateProxyWithNonceAddress',
      outputs: [
        {
          internalType: 'contract GnosisSafeProxy',
          name: 'proxy',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'singleton',
          type: 'address',
        },
        {
          internalType: 'bytes',
          name: 'data',
          type: 'bytes',
        },
      ],
      name: 'createProxy',
      outputs: [
        {
          internalType: 'contract GnosisSafeProxy',
          name: 'proxy',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_singleton',
          type: 'address',
        },
        {
          internalType: 'bytes',
          name: 'initializer',
          type: 'bytes',
        },
        {
          internalType: 'uint256',
          name: 'saltNonce',
          type: 'uint256',
        },
        {
          internalType: 'contract IProxyCreationCallback',
          name: 'callback',
          type: 'address',
        },
      ],
      name: 'createProxyWithCallback',
      outputs: [
        {
          internalType: 'contract GnosisSafeProxy',
          name: 'proxy',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_singleton',
          type: 'address',
        },
        {
          internalType: 'bytes',
          name: 'initializer',
          type: 'bytes',
        },
        {
          internalType: 'uint256',
          name: 'saltNonce',
          type: 'uint256',
        },
      ],
      name: 'createProxyWithNonce',
      outputs: [
        {
          internalType: 'contract GnosisSafeProxy',
          name: 'proxy',
          type: 'address',
        },
      ],
      stateMutability: 'nonpayable',
      type: 'function',
    },
    {
      inputs: [],
      name: 'proxyCreationCode',
      outputs: [
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      stateMutability: 'pure',
      type: 'function',
    },
    {
      inputs: [],
      name: 'proxyRuntimeCode',
      outputs: [
        {
          internalType: 'bytes',
          name: '',
          type: 'bytes',
        },
      ],
      stateMutability: 'pure',
      type: 'function',
    },
  ],
}
