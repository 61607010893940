export default {
  defaultAddress: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
  released: true,
  contractName: 'MultiSendCallOnly',
  version: '1.3.0',
  networkAddresses: {
    647: '0x7dca171397dE3D4C6F8b7822C3B0abb933F13114',
    416: '0xcf7a76CC31076dEF7CCDa74F768A65dC890C85eA',
    80001: '0x40A2aCCbd92BCA938b02010E17A5b8929b49130D',
  },
  abi: [
    {
      inputs: [
        {
          internalType: 'bytes',
          name: 'transactions',
          type: 'bytes',
        },
      ],
      name: 'multiSend',
      outputs: [],
      stateMutability: 'payable',
      type: 'function',
    },
  ],
}
